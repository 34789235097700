import { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../init/store'
import {
  requestDownloadRepresentativeness,
  requestStartVote,
  requestStopVote,
  requestVoteSectors,
} from './services'
import { shallowEqual } from 'react-redux'

export const useManageVote = (isDemo: boolean) => {
  const dispatch = useAppDispatch()
  const voteInfo = useAppSelector(
    (state) => ({
      closingTime: state.configuration.config?.closingTime,
      openingDate: state.configuration.config?.openingDate,
      closingDate: state.configuration.config?.closingDate,
      openingDateSimulation: state.configuration.config?.openingDateSimulation,
      closingDateSimulation: state.configuration.config?.closingDateSimulation,
    }),
    shallowEqual
  )

  const startVote = useCallback(() => dispatch(requestStartVote({ isDemo })), [dispatch, isDemo])
  const stopVote = useCallback(() => dispatch(requestStopVote({ isDemo })), [dispatch, isDemo])

  return { voteInfo, startVote, stopVote }
}

export const useVoteInfo = () => {
  const isDemo = useAppSelector((state) => state.appConfig.isDemo, shallowEqual)

  const voteInfo = useAppSelector(
    (state) => ({
      additionalVoteTime: state.configuration.config?.additionalVoteTime,
      closingTime: state.configuration.config?.closingTime,
      openingDate: state.configuration.config?.openingDate,
      closingDate: state.configuration.config?.closingDate,
      openingDateSimulation: state.configuration.config?.openingDateSimulation,
      closingDateSimulation: state.configuration.config?.closingDateSimulation,
      openMixDate: !isDemo
        ? state.configuration.config?.openingDate
        : state.configuration.config?.openingDateSimulation,
      closeMixDate: !isDemo
        ? state.configuration.config?.closingDate
        : state.configuration.config?.closingDateSimulation,
    }),
    shallowEqual
  )

  return voteInfo
}

export const useVoteSectors = () => {
  const dispatch = useAppDispatch()
  const getVoteSectors = useCallback(() => dispatch(requestVoteSectors()), [dispatch])

  const voteSectors = useAppSelector((state) => state.vote.voteSectors, shallowEqual)

  useEffect(() => {
    getVoteSectors()
  }, [getVoteSectors])

  return voteSectors || []
}

export const useRepresentativeness = () => {
  const dispatch = useAppDispatch()
  const downloaduRepresentativeness = useCallback(
    () => dispatch(requestDownloadRepresentativeness()),
    [dispatch]
  )

  return downloaduRepresentativeness
}
