import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios, getTokenHeaders, setAxios } from '../../utils/lib/requestAxios'

const download = (blob) => {
  const fileURL = window.URL.createObjectURL(blob)
  // Setting various property values
  let alink = document.createElement('a')
  alink.href = fileURL
  alink.download = 'recu-de-vote.pdf'
  alink.click()
}

const print = (blob) => {
  const fileURL = window.URL.createObjectURL(blob)
  //load content in an iframe to print later
  let iframe = document.createElement('iframe')
  document.body.appendChild(iframe)
  iframe.style.display = 'none'
  iframe.src = fileURL
  iframe.onload = function () {
    setTimeout(function () {
      iframe.focus()
      iframe.contentWindow?.print()
    }, 1)
  }
}

// Exports
const PDF_TYPES = {
  cse: '/cse/exports/vote-receipt',
  cse_registration: '/cse/exports/registration-receipt',
  res: '/res/exports/vote-receipt',
  res_final: '/res/exports/final-vote-receipt',
  uni: '/uni/exports/vote-receipt',
  list: '/lis/exports/vote-receipt',
  lis: '/lis/exports/vote-receipt',
}

const EMAIL_TYPES = {
  cse: '/cse/exports/email-vote-receipt',
  res: '/res/exports/email-vote-receipt',
  res_final: '/res/exports/email-final-vote-receipt',
  uni: '/uni/exports/email-vote-receipt',
  lis: '/lis/exports/email-vote-receipt',
}

export const requestPDF = createAsyncThunk<
  unknown,
  {
    pollId: string
    password?: string
    ballotPrint?: string
    action: string
    type?: string
    params?: any
    encryptedBallotContent?: string
  }
>(
  'receipt/requestPDF',
  async (
    { pollId, password, ballotPrint, encryptedBallotContent, action, type = 'cse', params = {} },
    { rejectWithValue }
  ) => {
    const headers = {
      responseType: 'blob',
      ...getTokenHeaders(),
    }

    const response: any = await setAxios(
      PDF_TYPES[type],
      {
        pollId,
        password,
        ballotPrint,
        encryptedBallotContent,
        encryptedBallotContents: encryptedBallotContent || [],
        ballotPrints: ballotPrint || [],
        ...params,
      },
      headers
    )

    if (response.error) {
      return rejectWithValue(response.error.statusCode)
    } else {
      if (action === 'download') download(response.data)
      else print(response.data)
      return
    }
  }
)

export const requestSendMail = createAsyncThunk<
  unknown,
  {
    pollId: string
    password?: string
    email: string
    ballotPrint?: string | any
    type?: string
    params?: any
    encryptedBallotContent?: string | any
  }
>(
  'receipt/requestSendMail',
  async (
    { pollId, password, email, ballotPrint, encryptedBallotContent, type = 'cse', params = {} },
    { rejectWithValue }
  ) => {
    const response: any = await setAxios(
      EMAIL_TYPES[type],
      {
        pollId,
        password,
        ballotPrint,
        encryptedBallotContent,
        encryptedBallotContents: encryptedBallotContent || [],
        ballotPrints: ballotPrint || [],
        email: email,
        ...params,
      },
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return
    }
  }
)

export const requestCheckBallot = createAsyncThunk<unknown, { pollId: string; print?: string }>(
  'receipt/requestCheckBallot',
  async ({ pollId, print }, { rejectWithValue }) => {
    const response: any = await getAxios(
      `/ballots/check?print=${print}${pollId ? `&pollId=${pollId}` : ''}`,
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestCheckBallotStates = (builder) => {
  builder.addCase(requestCheckBallot.pending, (state) => {
    state.isLoading = true
    state.receipt = {}
  })

  builder.addCase(requestCheckBallot.fulfilled, (state, { payload }) => {
    state.isLoading = false
    state.receipt = { ...payload, isError: false }
  })

  builder.addCase(requestCheckBallot.rejected, (state) => {
    state.isLoading = false
    state.receipt = { isError: true }
  })
}
