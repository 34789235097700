import { memo, useCallback, useEffect, useRef, useState } from 'react'

// Components
import Icon from '../Icon/Icon'
import Tooltip from '../Tooltip/Tooltip'
import { IonInput, IonTextarea } from '@ionic/react'
import { AutocompleteTypes, TextFieldTypes } from '@ionic/core'

// Styles & Types
import './TextInput.scss'
import IconTypes from '../../utils/types/IconTypes'
import {
  ALPHA_REGEX,
  NUMERIC_REGEX,
  FLOAD_NUMERIC_REGEX,
} from '../../pages/UserEdition/partials/constants'

import uniqId from 'shortid'

const ENTER_KEY = 13
const PREVENT_DEFAULT = (e) => e.preventDefault()

// For Safari
const dummyPass = {
  display: 'inline',
  padding: 0,
  margin: 0,
  width: 0,
  height: 0,
  border: 0,
}
const voidFunction = () => {}

interface Props {
  max?: any
  min?: any
  id?: string
  type?: TextFieldTypes | 'file' | undefined
  label?: string
  initValue?: string
  disabled?: boolean
  disablePaste?: boolean
  valueKey?: string
  placeholder?: string
  children?: any
  error?: boolean
  errorMessage?: string
  warning?: boolean
  warningMessage?: string
  className?: string
  name?: string
  textarea?: boolean
  rows?: number
  uppercase?: boolean
  lowercase?: boolean
  spellcheck?: boolean
  tooltip?: string
  autocomplete?: AutocompleteTypes
  maxLength?: number
  supleant?: string
  prioValue?: string | null
  pattern?: string
  matchOnly?: RegExp
  icon?: string
  readonly?: boolean
  clearOnDisabled?: boolean
  step?: string
  counter?: boolean
  suggestions?: string[]
  onFocus?: (ev: any, key?: string) => void
  onBlur?: (ev: any, key?: string) => void
  onSubmit?: (value: any, key: string | undefined) => void
  onChange?: (value: any, key: string | undefined) => void
  iconRight?: string
  onIconRightClick?: () => void
}

const TextInput: React.FC<Props> = ({
  id,
  type = 'search',
  label,
  disabled,
  disablePaste,
  initValue = '',
  className = '',
  name,
  prioValue = '',
  valueKey,
  placeholder,
  textarea,
  rows,
  max,
  min,
  error,
  errorMessage,
  warning,
  warningMessage,
  autocomplete,
  pattern,
  matchOnly,
  spellcheck,
  tooltip,
  uppercase = false,
  lowercase = false,
  maxLength,
  supleant,
  icon,
  onSubmit,
  onChange,
  onFocus,
  onBlur,
  children,
  readonly,
  clearOnDisabled,
  step = '1',
  counter = false,
  suggestions = [],
  iconRight,
  onIconRightClick,
}) => {
  const elementId = useRef(id || `textInput_${Date.now() + uniqId.generate()}`)
  const timer = useRef<NodeJS.Timeout | null>(null)
  const mounted = useRef<boolean>(false)
  const [value, setValue] = useState(initValue)
  const [_readOnly, setReadOnly] = useState(true)
  const [inputType, setInputType] = useState(type)
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([])
  const [showSuggestions, setShowSuggestions] = useState(false)

  // Setting initValue
  useEffect(() => {
    mounted.current = true
    let timerTimeoutInitValue
    let timerTimeoutDisablePaste
    if (initValue) {
      timerTimeoutInitValue = setTimeout(handleInitValue, 500)
    }
    if (disablePaste) {
      timerTimeoutDisablePaste = setTimeout(handleDisablePaste, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      mounted.current = false
      if (timerTimeoutInitValue) clearTimeout(timerTimeoutInitValue)
      if (timerTimeoutDisablePaste) clearTimeout(timerTimeoutDisablePaste)
    }
  }, [])

  useEffect(() => {
    if (clearOnDisabled) {
      const elem: any = document.getElementById(elementId.current)
      if (elem) {
        elem.value = disabled ? '' : prioValue || initValue
      }
    }
  }, [clearOnDisabled, disabled])

  useEffect(() => {
    const elem: any = document.getElementById(elementId.current)
    if (elem) {
      elem.value = prioValue
    }
    if (timer.current) clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      if (!readonly) setReadOnly(false)
    }, 500)
    return () => {
      if (timer.current) clearTimeout(timer.current)
    }
  }, [prioValue])

  // Handlers
  const handleChangeType = useCallback(
    () => setInputType(inputType === 'password' ? 'search' : 'password'),
    [inputType, setInputType]
  )

  const handleDisablePaste = useCallback(() => {
    const elem: any = document.getElementById(elementId.current)
    if (elem) {
      elem.onpaste = PREVENT_DEFAULT
    }
  }, [])

  const handleInitValue = useCallback(() => {
    const elem: any = document.getElementById(elementId.current)
    if (elem) {
      elem.value = initValue
    }
  }, [initValue])

  const handleFocus = useCallback(
    (ev) => onFocus?.(ev, valueKey || label),
    [label, onFocus, valueKey]
  )

  const handleBlur = useCallback(
    (ev) => {
      setTimeout(() => {
        //Necessaire sinon le clic sur une suggestion n'est pas pris en compte
        setShowSuggestions(false)
      }, 250)
      return onBlur?.(ev, valueKey || label)
    },
    [label, onBlur, valueKey, setShowSuggestions]
  )

  const handleChange = useCallback(
    (ev) => {
      if (disabled) return
      if (!ev.detail.value) {
        ev.detail.value = ''
      }
      const elem: any = document.getElementById(elementId.current)

      if (type === 'date' && ev.detail.value?.length) {
        const tDate = new Date(ev.detail.value)
        if (max) {
          const tMax = Number(new Date(max))
          if (Number(tDate) > tMax || isNaN(Number(tDate))) {
            ev.detail.value = new Date(max).toISOString().split('T')[0]
            if (elem) {
              elem.value = ev.detail.value
            }
          }
        } else if (tDate.getFullYear() > 9999 || isNaN(Number(tDate))) {
          tDate.setFullYear(new Date().getFullYear())
          ev.detail.value = new Date(tDate).toISOString().split('T')[0]
          if (elem) {
            elem.value = ev.detail.value
          }
        }
      }

      if (maxLength && ev.detail.value.length > maxLength) {
        ev.detail.value = ev.detail.value.slice(0, maxLength)

        if (elem) {
          elem.value = ev.detail.value
        }
      }

      if (type === 'number') {
        if (max && parseInt(ev.detail.value) > max) {
          const elem: any = document.getElementById(elementId.current)
          if (elem) {
            elem.value = max
          }
        } else if (min && parseInt(ev.detail.value) < min) {
          const elem: any = document.getElementById(elementId.current)
          if (elem) {
            elem.value = min
          }
        } else {
          if (mounted.current) setValue(ev.detail.value)
          onChange?.(ev.detail.value, valueKey || label)
        }
      } else if (uppercase) {
        if (mounted.current) setValue(ev.detail.value.toUpperCase())
        onChange?.(ev.detail.value.toUpperCase(), valueKey || label)
      } else if (lowercase) {
        if (mounted.current) setValue(ev.detail.value.toLowerCase())
        onChange?.(ev.detail.value.toLowerCase(), valueKey || label)
      } else {
        if (mounted.current) setValue(ev.detail.value)
        onChange?.(ev.detail.value, valueKey || label)
      }

      // Filter suggestions
      if (suggestions.length > 0) {
        const filtered = suggestions.filter((suggestion) =>
          suggestion.toLowerCase().includes(ev.detail.value.toLowerCase())
        )

        setFilteredSuggestions(filtered)
        if (filtered[0] == ev.detail.value) return
        setShowSuggestions(true)
      }
    },
    [disabled, label, maxLength, onChange, suggestions, type, uppercase, lowercase, valueKey]
  )

  const handleSuggestionClick = useCallback((suggestion) => {
    setValue(suggestion)
    onChange?.(suggestion, valueKey || label)
    setShowSuggestions(false)
  }, [])

  const handleKeyDown = useCallback(
    (ev) => {
      let newValue = ev.key
      let regAlpha = new RegExp(ALPHA_REGEX)
      let regNum = new RegExp(step !== '1' ? FLOAD_NUMERIC_REGEX : NUMERIC_REGEX)

      if (matchOnly && !matchOnly.test(newValue)) {
        ev.preventDefault()
        return
      }
      if (!regAlpha.test(newValue) && type === 'text') {
        ev.preventDefault()
        return
      }
      if (!regNum.test(newValue) && type === 'number') {
        ev.preventDefault()
        return
      }
      if (ev.charCode === ENTER_KEY && onSubmit) {
        ev.preventDefault()
        onSubmit?.(value, valueKey || label)
      }
    },
    [label, matchOnly, onSubmit, type, value, valueKey, step]
  )

  const handleWheel = useCallback((ev) => {
    ev.target?.blur?.()
  }, [])

  if (textarea) {
    return (
      <div className={`textInputBox ${error ? 'isError' : ''} ${className}`}>
        {!!label?.length && <div className='label'>{label}</div>}
        <div className='textInputGroup'>
          <IonTextarea
            id={elementId.current}
            rows={rows}
            autoGrow
            className={`textInput ${type} ${uppercase ? 'uppercase' : ''} ${
              lowercase ? 'lowercase' : ''
            }  ${disabled ? 'disabled' : ''}`}
            readonly={_readOnly}
            onIonChange={handleChange}
            onKeyPress={handleKeyDown}
            disabled={disabled}
            placeholder={placeholder}
            onBlur={handleBlur}
            onFocus={handleFocus}
            maxlength={maxLength}
          />
          {!!supleant && <div className='suppleant'>{supleant}</div>}
          {!!tooltip && (
            <div className='tooltip'>
              <Tooltip label={tooltip} />
            </div>
          )}
        </div>
        {!!(counter && maxLength) && (
          <div className='inputCounter'>
            <div className='counter'>
              {value ? value.length : 0}/{maxLength}
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={`textInputBox ${className} ${error ? 'isError' : ''}`}>
      {(!!label?.length || !!children) && (
        <div className='inputHeader'>
          {!!label?.length && <div className='label'>{label}</div>}
          {children}
        </div>
      )}
      <div className='textInputGroup'>
        <IonInput
          id={elementId.current}
          className={`textInput ${type} ${uppercase ? 'uppercase' : ''}  ${
            lowercase ? 'lowercase' : ''
          } ${disabled ? 'disabled' : ''}`}
          readonly={_readOnly}
          name={name}
          onIonChange={handleChange}
          onKeyPress={handleKeyDown}
          spellcheck={spellcheck}
          disabled={disabled}
          placeholder={placeholder}
          max={max}
          min={min}
          onWheel={handleWheel}
          pattern={pattern}
          // @ts-ignore
          type={inputType}
          autocomplete={autocomplete}
          onBlur={handleBlur}
          onFocus={handleFocus}
          maxlength={maxLength}
          step={step}
        />
        {!!iconRight && (
          <div className='iconBox' onClick={onIconRightClick}>
            <Icon icon={iconRight} />
          </div>
        )}
        {type === 'password' && !readonly && (
          <>
            <input
              type='new-password'
              name='dummypass'
              value={value}
              style={dummyPass}
              onChange={voidFunction}
            />
            <div className='iconBox' onClick={handleChangeType}>
              <Icon icon={inputType === 'password' ? IconTypes.EYE : IconTypes.EYE_OFF} />
            </div>
          </>
        )}
        {!!icon && (
          <div className='iconContainer'>
            <Icon icon={icon} />
          </div>
        )}
        {!!supleant && <div className='suppleant'>{supleant}</div>}
        {!!tooltip && (
          <div className='tooltip'>
            <Tooltip label={tooltip} />
          </div>
        )}
      </div>
      {!!error && <div className='errorMessage'>{errorMessage}</div>}
      {!!(counter && maxLength) && (
        <div className='inputCounter'>
          <div className='counter'>
            {value ? value.length : 0}/{maxLength}
          </div>
        </div>
      )}
      {!error && !!warning && !!warningMessage && (
        <div className='warningMessage'>{warningMessage}</div>
      )}
      {showSuggestions && filteredSuggestions.length > 0 && (
        <div className='suggestionsList'>
          {filteredSuggestions.map((suggestion, index) => (
            <div
              className='suggestions'
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default memo(TextInput)
