import { IonItem } from '@ionic/react'
import { useCallback } from 'react'
import Icon from '../../../../atoms/Icon/Icon'
import Tooltip from '../../../../atoms/Tooltip/Tooltip'
import useLocalize from '../../../../locales/hook'
import { useCredentialsInformations } from '../../../../redux/sealing/hooks'
import IconTypes from '../../../../utils/types/IconTypes'

const CellMemberButton: React.FC<any> = () => {
  const t = useLocalize()
  const {
    setShouldSendCredentials,
    isCellMember,
    shouldSendCredentials,
    memberConfirmedCredentials,
  } = useCredentialsInformations()

  const handleClick = useCallback(() => {
    if (memberConfirmedCredentials || !shouldSendCredentials) return

    setShouldSendCredentials(!shouldSendCredentials)
  }, [shouldSendCredentials])

  if (!isCellMember || memberConfirmedCredentials || !shouldSendCredentials) return null
  return (
    <IonItem lines='none' detail={false} className='headerToggle toggle' onClick={handleClick}>
      <Tooltip vertical='bottom' horizontal='left' label={t('head_credentials_title')}>
        <Icon icon={IconTypes.USER_KEY} />
      </Tooltip>
    </IonItem>
  )
}

export default CellMemberButton
