export enum STATUS_ENUM {
  starting = 'starting',
  open = 'open',
  closed = 'closed',
}

export enum PV_STATUS {
  ERROR_STATUS = 'DEFICIENCY',
  FORBIDDEN = 'FORBIDDEN',
  WAITING_STATUS = 'NOT_STARTED',
  INFO_PENDING = 'INFO_PENDING',
  INFO_VALIDATED = 'INFO_VALIDATED',
  IN_PROGRESS_STATUS = 'IN_PROGRESS',
  COUNT_PENDING_STATUS = 'COUNTING_PENDING',
  COUNT_DONE_STATUS = 'COUNTING_DONE',
  NEED_2ND_ROUND = 'NEED_2ND_ROUND',
}

export const getStatus = (
  today: number,
  openAt: Date | null,
  closesAt: Date | null,
  isSealed: boolean
) => {
  if (!openAt) return null
  if (!isSealed) {
    return STATUS_ENUM.starting
  } else {
    if (Number(today) < Number(openAt)) {
      return STATUS_ENUM.starting
    } else if (closesAt && Number(today) > Number(closesAt)) {
      return STATUS_ENUM.closed
    } else {
      return STATUS_ENUM.open
    }
  }
}
