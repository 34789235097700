import { createSlice } from '@reduxjs/toolkit'

// Services
import {
  requestParticipationStates,
  requestSignaturesStates,
  requestBallotCountStates,
} from './services'

// Utils

// Define a type for the slice state
export type SignaturesState = {
  signatures: { [collegeId: string]: any[] }
  totalUsers: { [collegeId: string]: any[] }
  participation: { [pollId: string]: any }
  ballotCounts: { [collegeId: string]: any }
}

// Define the initial state using that type
const initialState: SignaturesState = {
  totalUsers: {},
  signatures: {},
  participation: {},
  ballotCounts: {},
}

export const signatures = createSlice({
  name: 'signatures',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    requestSignaturesStates(builder)
    requestParticipationStates(builder)
    requestBallotCountStates(builder)
  },
})
